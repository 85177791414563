
  import { defineComponent } from "vue";
  import AddUser from "@/components/AddUser.vue";
  import Expenses from "@/components/Expenses.vue";
  import TotalAndSplit from "@/components/TotalAndSplit.vue";
  import { IUser, IExpense } from "@/types/interfaces";

  // const userList: IUser[] = [
  //   {
  //     id: 1,
  //     name: "Björn",
  //     avatar: "male1.png",
  //     phoneNumber: "070123456",
  //     expenses: [
  //       { id: 1, userId: 1, amount: 1700 },
  //       { id: 2, userId: 1, amount: 500 },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     name: "Hampus",
  //     avatar: "male1.png",
  //     phoneNumber: "070654321",
  //     expenses: [
  //       { id: 1, userId: 2, amount: 1500 },
  //       { id: 2, userId: 2, amount: 240 },
  //     ],
  //   },
  //   {
  //     id: 3,
  //     name: "Tomas",
  //     avatar: "female1.png",
  //     phoneNumber: "070121212",
  //     expenses: [],
  //   },
  // ];

  export default defineComponent({
    name: "UserList",
    setup() {},
    created() {
      this.users = [];
      // this.users = userList;
    },
    components: {
      AddUser,
      Expenses,
      TotalAndSplit,
    },
    data() {
      return {
        users: [] as IUser[],
        userToEdit: null as unknown as IUser,
        nextUserId: 1,
        calculating: false,
      };
    },
    methods: {
      addUser(userName: string) {
        let count = this.users.filter(
          (u) => u.name.substr(0, userName.length) === userName
        ).length;
        if (count > 0) {
          console.log(`Duplicate user name: ${userName} already added`);
          userName = `${userName}_${count + 1}`;
        }

        let user: IUser = {
          id: this.nextUserId++,
          name: userName,
          avatar: this.users.length % 2 ? "male1.png" : "female1.png",
          phoneNumber: "",
          expenses: [] as IExpense[],
        };

        this.users.push(user);
      },
      onUpdateUser(user: IUser) {
        this.userToEdit = user;
      },
      onSaveUser() {
        this.userToEdit = null as unknown as IUser;
      },
      onDeleteUser(user: IUser) {
        let index = this.users.findIndex((u) => u.id === user.id);

        if (index < 0) return;
        this.users.splice(index, 1);
      },
      addExpense(expense: IExpense) {
        this.users
          .find((user) => user.id === expense.userId)
          ?.expenses?.push(expense);
      },
      deleteExpense(expense: IExpense) {
        let user = this.users.find((u) => u.id === expense.userId);
        if (!user) return;

        let expenseIndex = user?.expenses?.findIndex(
          (e) => e.id === expense.id
        );

        if (expenseIndex < 0) return;
        user.expenses.splice(expenseIndex, 1);
      },
      calculateSplit() {
        this.calculating = true;
      },
    },
    computed: {
      totalAmount() {
        let sum = 0;
        for (let index = 0; index < this.users.length; index++) {
          const expenses = this.users[index].expenses;
          sum += expenses.reduce((sum, current) => sum + current.amount, 0);
        }
        return sum;
      },
    },
  });
