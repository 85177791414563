
  import { Options, Vue } from "vue-class-component";
  import UserList from "@/components/UserList.vue";
  import TotalAndSplit from "@/components/TotalAndSplit.vue";

  @Options({
    components: {
      UserList,
      TotalAndSplit,
    },
  })
  export default class App extends Vue {}
