
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "AddUser",
    setup() {
      return {};
    },
    data() {
      return {
        userName: "",
      };
    },
    methods: {
      onAddUser() {
        if (!this.userName) return;
        this.$emit("addUser", this.userName);
        this.userName = "";
      },
    },
  });
