
  import { defineComponent, PropType } from "vue";
  import { IExpense } from "@/types/interfaces";

  export default defineComponent({
    name: "Expenses",
    setup() {
      return {};
    },
    props: {
      userId: {
        required: true,
        type: Number,
      },
      expenses: {
        required: true,
        type: Array as PropType<IExpense[]>,
      },
    },
    methods: {
      onAddExpense() {
        let amount = parseInt(this.amount);
        if (isNaN(amount)) return;

        let newExpense: IExpense = {
          id: this.expenses.length > 0 ? this.expenses.length + 1 : 1,
          userId: this.userId,
          amount: parseInt(this.amount),
        };
        this.$emit("addExpense", newExpense);
        this.amount = "";
      },
      onDeleteExpense(expense: IExpense) {
        this.$emit("deleteExpense", expense);
      },
    },
    data() {
      return {
        amount: "",
      };
    },
    computed: {
      total: function () {
        let expenses = this.expenses?.reduce(
          (sum: number, current: IExpense) => sum + current.amount,
          0
        );

        return expenses;
      },
    },
  });
