
  import { defineComponent } from "vue";

  export default defineComponent({
    props: {
      totalAmount: {
        required: true,
        type: Number,
      },
    },
    data() {
      return {
        total: "",
      };
    },
    methods: {
      calculateSplit() {
        this.$emit("calcualteSplit");
      },
    },
  });
